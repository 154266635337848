.form{
    margin-bottom: 50px;
}

.new-container {
    width: 90%;
    border-top: 3px solid $blue;
    margin: 20px auto 0px auto;
}

.new-lable {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 25px;
    font-family: $new-font;
    font-size: 4.5rem;
    width: 160px;
    margin: 0px auto;
    background:  $ltblue;
    border: 3px solid $blue;
    border-radius: 20px;
}

.new-text {
    animation:blinking 2s infinite;
    position: relative;
    top: 5px;    
}

@keyframes blinking{
    0%{     color: red; text-shadow: 2px 2px 3px black;   }
    49%{    color: red;text-shadow: 4px 4px 3px black;}
    60%{    color: transparent; text-shadow: none;}
    99%{    color:transparent; text-shadow: none; }
    100%{   color: red; text-shadow: 2px 2px 3px black;   }
}