.btm-title{
    font-size: 4rem;
    text-align: center;
    color: $blue;
    margin: 50px;
}
.bottom-tag-container{
    width: 95%;
    margin: 10px auto;
    display: flex;
    justify-content: space-around;
    
    @media (max-width: $small-pc) {
        flex-direction: column;
        align-items: center;    
    }

    div{
        text-align: center;
        width: 300px;
    }

    h3{
        font-size: 2rem;
    }

    img{
        width: 270px;
        height: 270px;
        border-radius: 50%;
        border: 2px solid $yellow;
        box-shadow: 1px 1px 1px $ltblue;
        margin: 20px;
    }

    .btm-tag-title{
        height: 75px;
    }

    .btm-tag-info{
        margin-bottom: 50px;
        font-size: 1.5rem;

        p{
            margin: 5px 0px;
        }

        a{
            color: black;
        }
    }
}

.payment{
    width: 50%;
    margin: 10px auto;
    text-align: center;
    font-size: 1.6rem;

    p{
        margin-top: 10px;
        margin-bottom: 20px;
    }

    img{
        height: 50px;
    }

    a{
        color:black;
    }

    
}

.vendor-cafe{
    padding: 5px;
    background: rgb(14, 11, 10);
    width: 250px;
    margin: 0 auto;
    margin-bottom: 25px;
    border-radius: 25px;
    img{
        height: 30px;
    }
}

.nexus{
    width: 165px;
    margin: 0 auto;
    background: rgb(14, 11, 10);
    padding: 10px;
    margin-bottom: 20px;

    img{
        height: 30px;
    }
}

.noaa{
    margin: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p{
        text-align: center;
        font-size: 2.5rem;
        margin-bottom: 5px;
    }

    img{
        width: 400px
    }
}