
.about{
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
   
    h1{
        font-size: 3.5rem;
        color: $blue;
        text-decoration: underline;
    }
}


.about-container{
    display: flex;
    margin: 0px 70px;
    h2{
        font-size: 1.6rem;
        font-weight: bold;
        margin: 5px 0px;
    }
    
    p{
        font-size: 1.6rem;
        margin-bottom: 20px;
    }

    p:nth-child(1){
        font-size: 1.6rem;
        margin-bottom: 30px;
    }

    div:nth-child(1){
        width: 60%;
    }

    div:nth-child(2){
        padding-left: 30px;
    }

    img{
        height: 200px;
    }

    @media (max-width: $tablet) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        div:nth-child(1){
            width: 100%;
        }

        div:nth-child(2){
            padding: 20px;
        }

    }

}

.about-bottom{
    display: flex;
    margin: 70px;
    text-align: center;
    
    .about-tag-box{
        width: 50%;
        min-height: 500px;
        padding: 20px;

        p{
            font-size: 1.6rem;
            margin: 40px 0px;
        }
    }

    img{  
        width: 500px;
    }

    .about-tag-pic{
        width: 100%;
        height: 300px;
        overflow: hidden;
    }

    h3{
        font-size: 2.4rem;
        margin: 20px 0px;

    }


    @media (max-width: $tablet) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px;

        .about-tag-box{
            width: 100%;
        }

        img{
            width: 100%;
        }
        

    }
}