
header {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;

    img{
        width: 50px;
        height: 50px;
        margin-right: 10px;
        vertical-align: middle;
    }

    h1 {
        font-family: $logo-font;
        font-size: 3.3rem;
        color: $blue;
        text-shadow: 1px 1px 2px $yellow, 1px 1px 3px $black; 
        a{
            color: $blue;
        } 
    }

    @media (max-width: $tablet) {
        display: block;
        text-align: center;
        
    }
}

.ph-num {
    font-size: 2.8rem;
    margin-top: 8px;
    a{
        color: black;
    }
}

.main-nav{
    @media (max-width: $tablet) {
        display: none;
    }
}

.top-nav{
    height: 50px;
    padding-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $blue-ltblue;

    @media (max-width: $tablet) {
        justify-content: flex-end;
        padding-top: 0px;
    }
}

nav {
    ul {
        display: flex;
        justify-content: center;
        align-items: center;  
    }

    li {
        width: 150px;
        text-align: center;
        font-size: 1.4rem;
        color: $yellow;
        text-shadow: 1px 1px 2px $black;
        
        &:hover {
            font-weight: bolder;
            text-decoration: underline;    
        }
    }
}

.menu-btn{
    display: none;
    @media (max-width: $tablet) {
        display: block;
        margin-right: 20px;;
        font-size: 1.5rem;
    }
}

.small-nav-1{
    ul { 
        margin-top: 25px;
        
    }
    
    li { 
        height: 40px;
        color: #ffff00;
        text-shadow: 1px 1px 2px black; 
        
        &:hover{
            font-weight: bolder;
            text-decoration: underline; 
        }
    }
    
    @media (max-width: $tablet) {
        position: absolute;
        border-top: 1px solid $black;
        width: 100%;
        text-align: center;
        font-size: 1.5rem;
        background: $blue-ltblue;
        overflow: hidden; 
    }
}

.small-nav-an{
    animation-name: nav-bar;
    animation-duration: 2.5s;
}
@keyframes nav-bar{
    from{height: 0px; font-size: 0rem}
    to{height: 226px; font-size: 1.5rem}
}
