.electro-title{
    text-align: center;
    font-size: 3rem;   
}

.electro-tagline{
    font-size: 1.6rem;
    text-align: center;

    div{
        margin-top: 15px; 
    }
}

.electro-container{
    width: 80%;
    height: 250px;
    margin: 50px auto;
    border: 5px solid $blue;
    border-radius: 120px;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 1000px) {
        justify-content: center; 
        margin: 10px auto 10px auto;   
    }  
}

.electro-info-container{
    overflow: hidden;
    margin: 5px;
    padding: 20px;
    width: 60%;
    height: 230px;

    @media (max-width: 1000px) {
        width: 90%;    
    }  
}

.electro-img{
    position: absolute;
     
    @media (max-width: 1000px) {
        position: static;
        text-align: center;    
    }   
}

.static-girl{
    position: relative;
    width: 350px;
    height:350px;
    left: 90px;
    bottom: 50px;
    border-radius: 100%;

    @media (max-width: 1000px) {
        position: static; 
        width: 300px;
        height:300px;   
    } 
}

.electro-box{
    margin: 100px 0 100px 0;
    @media (max-width: 1000px) {
        margin: 10px; 
           
    }
}

.electro-info-box{
    width: 95%;
}

.electro-info-title{
    font-size: 2rem;
    position: relative;
    animation-name: title-slide;
    animation-duration: 2s;
    animation-timing-function: ease-out;
    margin-top: 20px;
    margin-bottom: 40px;

    @media (max-width: $phone) {
        font-size: 1.4rem;
        text-align: center;    
    } 
    
}

.electro-info-content{
    font-size: 1.8rem;
    opacity: 0;
    animation-name: appear;
    animation-delay: 2s;
    animation-duration: 5s;
    animation-fill-mode: forwards;
    
    @media (max-width: 530px) {
        font-size: 1.2rem;    
    } 
}

.electro-number{
    text-align: center;
    font-size: 3rem;
    p{
        margin-bottom: 10px;;
    }
    @media (max-width: $phone) {
        font-size: 2rem;    
    } 

}

.electro-title-spacing{
    text-align: center;
}


@keyframes title-slide {
    from {right: 500px;}
    to {right: 0px;}
}

@keyframes appear {
    from {opacity: 0;}
    to {opacity: 1;}
}

