section{
    padding: 20px;
}
  
.home-main-section{
    display: flex;
    @media (max-width: $small-pc) {
        flex-direction: column;    
    }
}
  
.tag-box{
    width: 40%;
    height: 370px;
    border: 4px outset #4d79ff;
    background: url("../../components/pages/home/components/tagimg/blue-yellow-drop.jpg");
    background-size: cover;
    background-position: center;
    text-align: center;
    //padding: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
        width: 88%;
        margin-bottom: 25px;
        font-size: 4rem;
        font-family: $tag-font;
        color: $blue ;
        text-shadow: 2px 2px 1px $yellow, -1px -1px 0px $yellow; 
    }

    p {
        font-size: 2rem;
        margin: 10px;
        color: $white;
        text-shadow: 2px 2px 1px $black;    
    }

    @media (max-width: $small-pc) {
        width: 100%;
        height: 400px;
    }

    @media (max-width: $phone){
        h2{
            font-size: 3rem;
        }

        p {
            font-size: 1.2rem;
        }
    }
   
}

.tag-line-2 {
   
    margin-top: 40px;
}

.pic-real-box {
    width: 60%;
    height: 370px;
    border: 1px solid $blue; 
    background: $ltblue;

    @media (max-width: $small-pc) {
       width: 100%;
    }
}

.pic-box {
    width: 100%;
    height: 85%;
    overflow: hidden;
    
    img {
        width: 100%;
    }
}

.pic-descript{
    font-size: 1.2rem;
    margin: 10px 10px;
}
  
.pic-an{
    animation-name: pic-real;
    animation-duration: 2s;
}

@keyframes pic-real{
    from{opacity: 0.1;}
    to{opacity: 1;}
}

.home-message{
    padding: 40px;
    text-align: center;
}