.camp-header{
    text-align: center;
    margin-top: 20px;
    h1{
        font-size: 3.5rem;
        color: $blue;
        text-decoration: underline;
        
    }
}

.camp-acronym{
    font-size: 1.6rem;
    text-align: center;
    margin: 40px;
}

.large-letter{
    font-size: 2.2rem;
    font-weight: bold;
    color: $blue;
}

.camp-text-area{
    text-align: center;
    font-size: 3rem;

    .subtext{
        margin: 10px auto;
        font-size: 2rem;
        max-width: 800px;
    }

    .sub-it{
        font-style: italic;
    }
}

.camp-info{
    text-align: center;
    font-size: 1.8rem;
    .blue{
        color: $blue;
    }

    .camp-list{
        width: 600px;
        margin: 20px auto;
        text-align: start;
        list-style-type: circle;
        list-style-position: inside;

        @media (max-width: $tablet) {
            width: 100%;
        }
    }
}

.camp-pics{
    display: flex;
    justify-content: space-around;
    margin-top: 30px;;

    img{
        width: 30%;
        height: auto;
        align-self: flex-start;
    }

    @media (max-width: $tablet) {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img{
            width: 80%;
            margin-bottom: 30px;
            align-self: center;
        }
    }
}