
.before-after-title{
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
    h1{
        font-size: 3.5rem;
        color: $blue;
        text-decoration: underline;
        
    }
}

.before-after-heading{
    text-align: center;
    margin-bottom: 40px;

    h2{
        font-size: 2.2rem;

    }

    p{
        font-size: 1.2rem;
        margin-top: 10px;;
    }
}

.before-after-main{
    font-size: 2rem;

}

.multi-pic{
    width: 60%;
    margin: 20px auto;
    border: 1px solid $yellow;
    border-radius: 10px;
    padding: 10px;
    background: $ltblue;
    color: white;
    box-shadow: 2px 2px 4px gray;

    h3{
        font-size: 2.2rem;
        color: $yellow;
        text-shadow: 1px 1px 1px #000000;
        text-decoration: underline;

    }

    @media (max-width: 1000px) {
        width: 98%;   
    } 
}

.multi-before{
    margin-top: 20px;
    p{
        align-self: center;
        margin-left: 20px;
        width: 50%;
    }    
    
}

.multi-after{
    justify-content: space-around;
    margin-top: 10px;
    margin-bottom: 10px;
    border-top: 2px solid $blue;
    padding-top: 10px;
}

.after-desc{
    text-align: center;
}


.single-container{
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;

    p{
        width: 50%;
        margin-left: 10px;
    }

    @media (max-width: $phone) {
        p{
            padding-left: 6px;
            font-size: 1.8rem;
        }   
    } 
}

.single-container:nth-child(odd){
    flex-direction: row-reverse;
}

.single-pic{
    width: 300px;
}