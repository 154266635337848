/* display */
.disNone{
    display: none;
    }
  
.disBlock{
    display: block;
    }

.flex{
    display: flex;
}