footer{
    background: $blue-ltblue;
    padding: 20px;

    .socal{
        margin-bottom: 20px;
        text-align: center;
        font-size: 3rem;
        color: $yellow;
        text-shadow: 1px 1px 1px black;

        a{
            margin-right: 8px;
            color: $yellow;
            &:last-child{
                margin-right: 0px;
            }
        }

        

    }

    .copyright{
        margin-top: 25px;
        text-align: center;
        font-size: 1.2rem;
        color: $white
    }
    .iandb{
        margin-top: 15px;
        text-align: center;
        font-size: 1.4rem;
        color: $white
    }
    
    @media (max-width: $tablet) {
        nav {
            display: none;  
        }
        
        .copyright {
            margin-top: 0px;
        }
            
    }
}


  
